@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@font-face {
  font-family: omegle;
  src: url(./../../fonts/omg2.ttf);
}
.errorTop {
  width: 100%;
}
.errorTop div {
  width: calc(90% - 80px);
  padding: 40px;
  margin: 100px auto;
  height: 70%;
  background: #1e1e1e;
  border-radius: 40px;
}
.errorTop div img {
  max-width: 1000px;
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 0px;
  margin: auto;
}
.errorTop div h1 {
  text-align: center;
  margin-bottom: 10px;
  background-image: linear-gradient(
    92.96deg,
    #9945ff 0.58%,
    #1bf5a0 98.97%
  ) !important;
  font-size: 70px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: omegle !important;
}
.errorTop div a{
    text-decoration: none;
}
.errorTop div button {
  width: 300px;
  height: 49px;
  margin: auto;
margin-top: 30px;
  display: block;
  border: none;
  background: linear-gradient(94.61deg, #9945ff 3.7%, #1bf5a0 100%);
  border-radius: 40px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
