/* Additional customizations are placed in tailwind.config.js and custom-theme.js*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@layer base {

  body {
    /* Applies background color from theme (as defined in tailwind.config.js) */
    @apply bg-background;
  }

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    @apply text-white;
  }

  h1 {
    @apply text-2xl lg:text-3xl mb-8;
  }
  h1.display-hero {
    @apply lg:text-5xl !leading-tight;
  }

  h2 {
    @apply text-xl lg:text-2xl;
  }
  h2.display-hero {
    @apply lg:text-4xl !leading-tight;
  }

  h3 {
    @apply text-lg lg:text-xl mb-5;
  }

  h4 {
    @apply text-base lg:text-lg;
  }

  p, ol, li {
    @apply text-white text-sm md:text-base;
  }

  small {
    @apply text-white;
  }

  hr.separator-default {
    @apply my-10 lg:my-12 w-10/12 mx-auto border-secondary;
  }

  div.unclickable-card {
    @apply p-4 border border-primary rounded-lg bg-primary-15 shadow-none;
  }

  button.clickable-card {
    @apply p-4 border border-secondary rounded-lg bg-secondary-15 transition-all duration-500 cursor-pointer w-full h-full;
  } 

  button.clickable-card:hover {
    @apply border-secondary-bright;
  }

}


@layer components {

  button {
    @apply shadow-none;
  } 

  textarea {
    @apply !text-base !text-white outline outline-0 focus:outline-0 !bg-primary-70 disabled:!bg-black/20 disabled:!text-grayscale-lucky placeholder-shown:border placeholder-shown:!border-t-primary placeholder-shown:border-r-primary placeholder-shown:border-b-primary placeholder-shown:border-l-primary focus:border-2 !border-t-transparent focus:!border-t-transparent placeholder:opacity-0 focus:placeholder:opacity-100 hover:!bg-primary/15;
  }

  textarea.custom-textarea + label {
    @apply !text-white text-[0.875rem] before:border-primary after:border-primary;
  }

}




/******* TYPOGRAPHY *******/

@font-face {
  font-family: 'Tekros-ExtraBold';
  src: url('./fonts/Tekros-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SplineSans-Regular';
  src: url('./fonts/SplineSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SplineSans-Bold';
  src: url('./fonts/SplineSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

h1, 
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Tekros-Extrabold', serif;
}

.emphasis,
strong,
b {
  font-family: 'SplineSans-Bold', sans-serif;
  font-weight: 700;
}




/******* IMAGE ADJUSTMENTS *******/

#tektools-logo {
  width: 122px;
  height: 16px;
}

#tektools-logo-footer {
  width: 184px;
  height: 22px;
}

.tekros {
  font-family: 'Tekros-ExtraBold', serif;
}




/******* STRUCTURE *******/

body {
  background-image: url("./img/bg-home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: theme('colors.background');;
}

html {
  scroll-behavior: smooth;
}


/*
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*/

.rdw-editor-main {
  background: #242424;
  padding: 0px 20px;
  height: 300px !important;
  color: #fff;
  margin-bottom: 30px;
}

.Toastify__toast-container {
  z-index: 9999 !important;
}

@keyframes colorChange {
  0% { color: #00A7EE; }
  33% { color: #7963F7; }
  66% { color: #B420FF; }
  100% { color: #00A7EE; }
}

.color-change {
  animation: colorChange 10s infinite;
}




/******* SIDEBAR *******/

.sidebar-manager > nav > a:last-child > div {
  border-bottom: none;
}




/******* LINKS *******/

p a {
  cursor: pointer;
}

section a,
footer a {
  color: theme('colors.interactive.bright');
  &:hover, 
  &:active {
    text-decoration: underline;
  }
}




/******* LISTS *******/

.features-list {
  list-style-image: url("./img/icon-feature-list.svg");
  list-style-position: inside;
  margin: 0.5rem 0;
  li {
    margin: 0.5rem 0;
  }
}




/******* BUTTONS *******/


button > svg {
  margin-right: 4px;
}

.button-primary {
  height: auto;  
  color: white;
  max-height: 48px;
  line-height: 1rem; 
  background-color: theme('colors.interactive.DEFAULT');
  &:hover {
    color: black;
    background-color: white;
    img {
      filter: none;
    }
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    filter: invert();
    transition: all ease-in-out 150ms;
  }
  &.px-4{
    max-height: 32px;
    img {
      width: 16px;
      height: 16px;
    }
  }  
}

.button-secondary {
  height: auto;
  max-height: 48px;
  line-height: 1rem;   
  color: theme('colors.interactive.bright');
  background-color: transparent;
  border: 2px solid theme('colors.interactive.bright');
  &:hover {
    opacity: 0.6;
    color: white;
    border-color: white;
    img {
      filter: invert();
    }
  }  
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;    
    filter: invert(100%) sepia(21%) saturate(5082%) hue-rotate(184deg) brightness(89%) contrast(74%);
    transition: all ease-in-out 150ms;
  }
  &.px-4{
    max-height: 32px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}




/******* INPUTS AND TEXTAREA *******/

input, 
textarea {
  &::selection {
    background-color: white;
    color: black;
  }
  &::placeholder {
    color: theme('colors.grayscale.lucky');
  }
}

input[disabled], 
textarea[disabled] {
  cursor: not-allowed;
}

textarea[disabled] {  
  border-width: 1px !important;
  border-color: theme('colors.primary.DEFAULT') !important;
  &:not(:placeholder-shown ) {
    border-top: none;
  }
}

textarea[disabled]:placeholder-shown + label {
  &::before {
    border: none;  
  }
  &::after {
    border: none;    
  } 
}

textarea[disabled] + label {
  color: theme('colors.grayscale.lucky') !important;
  &::before {
    border-top-width: 1px !important;
    border-top-color: theme('colors.primary.DEFAULT') !important;    
    border-left-width: 1px !important;
    border-left-color: theme('colors.primary.DEFAULT') !important;       
  }
  &::after {
    border-top-width: 1px !important;
    border-top-color: theme('colors.primary.DEFAULT') !important;       
    border-right-width: 1px !important;
    border-right-color: theme('colors.primary.DEFAULT') !important;       
  }  
}

input[readonly], 
textarea[readonly] {
  cursor: default;
  background-color: rgba(0,0,0,0.2) !important;
  color: theme('colors.grayscale.bright');
  &:hover {
    background-color: rgba(0,0,0,0.2);
  }
  &:focus {      
    border-width: 1px;
  }
}

input[readonly] + label, 
textarea[readonly] + label {
  color: theme('colors.grayscale.bright') !important;
  font-size: 0.875rem !important;
}

input[readonly]:focus + label,
textarea[readonly]:focus + label {
  color: theme('colors.grayscale.bright') !important;
  font-size: 0.875rem !important;  
  &::before {
    border-top-width: 1px !important;
    border-left-width: 1px !important;
  }
  &::after {
    border-top-width: 1px !important;
    border-right-width: 1px !important;
  }
}

textarea:focus + label {
  font-size: 0.875rem !important;  
}

textarea:not(:focus) {
  border-right-color: theme('colors.primary.DEFAULT') !important;
  border-bottom-color: theme('colors.primary.DEFAULT') !important; 
  border-left-color: theme('colors.primary.DEFAULT')!important;     
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field-description {
  display: table;
  padding: 4px;
  line-height: 18px;
  opacity: 0.75;
}

.input-file-upload {
  border: 1px solid theme('colors.primary.DEFAULT');
  border-radius: 4px;
  height: 48px;
  width: 100%;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  background-color: theme('colors.primary.70');
  color: white;

}




/******* DATEPICKER *******/

.rmdp-input {
  font-size: 1rem !important;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 12px !important;
  border: 1px solid theme('colors.primary.DEFAULT') !important;
  border-radius: 4px !important;
  background-color: theme('colors.primary.70');
  color: white;
}




/******* SELECTS *******/

.custom-input-label {
  margin-bottom: -16px;
  z-index: 1;
  position: relative;
  > div:first-child {
    width: 10px;
  }
  > div:last-child {
    flex-grow: 1;
  }  
}

.custom-input-label-text {  
  color: white;
  padding: 0 3px;
  position: relative;
  font-size: 0.875rem;
  .label-border {
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: theme('colors.background');
    top: 7px;
    left: 0px;
    z-index: -1;
  }
}

.select-custom {
  cursor: pointer;
  span[class*="-indicatorSeparator"]{
    background-color: theme('colors.primary.DEFAULT');
  }
  div[class*="-indicatorContainer"] {
    color: white;
    &:hover {
      color: theme('colors.interactive.bright');
    }
  } 
  + div[class*="-menu"] {
   z-index: 2;
  }   
}




/******* CARDS *******/

.unclickable-card {
  hr {
    margin: 1.25rem 0;
    border-color: theme('colors.primary.DEFAULT');
  }
}

.server-card {
  .server-blurred-bg-image { 
    opacity: 0.5;
    position: absolute;
    inset: 0;
    filter: blur(12px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150%;
    z-index: 0;
  }
  .server-icon {
    width: 120px;
    height: 120px;
    z-index: 1;    
  }
}

.clickable-card {
  box-shadow: 0 4px 8px 8px rgba(0,0,0,0.25);
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(125, 110, 141, 0.33);
    box-shadow: 0 8px 8px 8px rgba(0,0,0,0.33);
    border-color: white;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.33;
  }
}

.card-wallet-solana-text {
  text-transform: uppercase;
  color: #11eeaa;
}

.card-wallet-bitcoin-text {
  text-transform: uppercase;
  color: #F7931A;
}

.card-wallet-ethereum-text {
  text-transform: uppercase;
  color: #919abc;
}




/******* BADGES *******/

.badge-accent-blue {
  background-color:  theme('colors.primary.70');
  color:  theme('colors.accent.blue');
}

.badge-accent-green {
  background-color:  theme('colors.primary.70');
  color:  theme('colors.accent.green');
}

.badge-accent-gold {
  background-color:  theme('colors.secondary.15');  
  color: theme('colors.accent.gold');
}

.badge-accent-sun {
  background-color:  theme('colors.secondary.15');  
  color: theme('colors.accent.sun');
}

.badge-gray {
  background-color:  theme('colors.secondary.15');  
  color:  theme('colors.grayscale.bright');
}




/******* TABLES *******/

table {
  color: white;
  tr:hover {
    background-color: rgba(255,255,255,0.15) !important;
  }
  thead {
    th {
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border: none;
      }      
    }
    td {

    }
  }
  th,
  td {
    padding: 8px 14px;
    border-right: 1px solid theme('colors.grayscale.deep');
  }
  tr:nth-child(even) {
    background-color: theme('colors.secondary.15');
  }  
  .action-cell {
    text-align: center;
    padding: 0;
    border: none;
  }
}




/******* BANNERS *******/

.banner-info {
  background: radial-gradient(92.15% 83.66% at 20.66% 21.65%, #1A5667 0%, #031F27 100%);
  border: 1px solid theme('colors.banner.infoStroke');
  border-radius: 0.25rem;
  padding: 1rem; 
  margin: 1.5rem auto;
  h4 {
    color: theme('colors.accent.blue');
    padding-bottom: 0.5rem;
  }
}

.banner-standard {
  background: radial-gradient(92.15% 83.66% at 20.66% 21.65%, #67551A 0%, #272403 100%);
  border: 1px solid theme('colors.banner.standardStroke');
  border-radius: 0.25rem;
  padding: 1rem; 
  margin: 1.5rem auto;
  h4 {
    color: theme('colors.accent.sun');
    padding-bottom: 0.5rem;
  }  
}




/******* SVG COLOR FILTERS *******/


.filter-white {
  filter: invert();
}

.filter-secondary {
  filter: invert(36%) sepia(6%) saturate(816%) hue-rotate(227deg) brightness(92%) contrast(90%);
}

.filter-secondary-bright {
  filter: invert(100%) sepia(21%) saturate(5082%) hue-rotate(184deg) brightness(89%) contrast(74%);
}

.filter-grayscale-bright {
  filter: invert(100%) sepia(0%) saturate(2353%) hue-rotate(255deg) brightness(76%) contrast(118%);
}

.filter-accent-blue {
  filter: invert(75%) sepia(20%) saturate(5030%) hue-rotate(143deg) brightness(98%) contrast(94%);
}

.filter-accent-green {
  filter: invert(74%) sepia(82%) saturate(465%) hue-rotate(91deg) brightness(99%) contrast(91%);
}

.filter-accent-gold {
  filter: invert(76%) sepia(83%) saturate(388%) hue-rotate(349deg) brightness(98%) contrast(96%);
}

.filter-accent-sun {
  filter: invert(76%) sepia(23%) saturate(7051%) hue-rotate(358deg) brightness(100%) contrast(107%);
}




/******* MISC *******/

#designed-by {
  opacity: 0.5;
}


/* PROFILE SPECIALS */

div.profile-x-twitter:after {
  content:'';
  height:180px;
  width:180px;
  right:0px;
  top:-25px;
  position: absolute;
  background-image: url("./img/icon-x-twitter.svg");
  background-repeat: no-repeat;  
  filter: invert();
  opacity:0.2;
  z-index: -1;
}

div.profile-telegram:after {
  content:'';
  height:180px;
  width:180px;
  right:0px;
  top:-25px;
  position: absolute;
  background-image: url("./img/icon-telegram.svg");
  background-repeat: no-repeat;
  filter: invert(47%) sepia(67%) saturate(563%) hue-rotate(155deg) brightness(102%) contrast(92%);
  opacity:0.2;
  z-index: -1;
}