.errorTop {
  margin: 0px !important;
}
.errorTop img {
  width: 50% !important;
  height: auto !important;
  margin: 30px auto !important;
}
.errorTop div {
  margin: 0px !important;
}
